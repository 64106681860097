import React from "react"

// UI
import Headline from "~ui/Headline"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"
import { faLink } from "@fortawesome/pro-light-svg-icons"
import { faFacebook, faTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

const ShareButton: React.FC<{ link: string; bgColor: string; icon: IconProp; onClick(): void }> = (props) => (
  <a
    href={props.link}
    onClick={props.onClick}
    target="_new"
    className="flex rounded-md md:rounded-lg text-white text-xs font-semibold px-2 md:px-3 py-1 md:py-2 items-center ml-1"
    style={props.bgColor && { backgroundColor: props.bgColor }}
  >
    <Icon icon={props.icon} size="1x" className=" mr-1 md:mr-2" />
    {props.children}
  </a>
)

const BlogShare: React.FC<TBlogShareProps> = ({ postURL }: TBlogShareProps) => {
  function copyLink(event) {
    event.preventDefault()
    navigator.clipboard.writeText(`https://canopyservicing.com/blog/${postURL}`)
    alert("The article link has been copied to your clipboard")
  }

  return (
    <div
      className="sm:px-6 md:px-8 sm:py-4 md:py-6 mt-10 md:mt-16 sm:bg-gray-200 sm:dark:bg-gray-800 rounded-2xl sm:flex justify-between items-center text-base fs-unmask"
      data-sal="slide-up"
    >
      <Headline level={5} className="mb-2 sm:mb-0">
        Share this Article
      </Headline>
      <div className="flex">
        <ShareButton
          link={`https://twitter.com/intent/tweet?text=Check%20out%20this%20article%20from%20%40canopyservicing%20canopyservicing.com/blog/${postURL}`}
          icon={faTwitter}
          bgColor="var(--color-twitter)"
        >
          Twitter
        </ShareButton>
        <ShareButton
          link={`https://www.linkedin.com/shareArticle?mini=true&url=canopyservicing.com/blog/${postURL}&title=Check%20out%20this%20article%20from%20Canopy%20Servicing`}
          icon={faLinkedin}
          bgColor="var(--color-linkedin)"
        >
          LinkedIn
        </ShareButton>
        <ShareButton
          link={`https://www.facebook.com/sharer/sharer.php?u=canopyservicing.com%2Fblog%2F${postURL}`}
          icon={faFacebook}
          bgColor="var(--color-facebook)"
        >
          Facebook
        </ShareButton>
        <ShareButton
          icon={faLink}
          bgColor="var(--gray-900)"
          link="#"
          onClick={(event) => {
            copyLink(event)
          }}
        >
          Link
        </ShareButton>
      </div>
    </div>
  )
}

interface TBlogShareProps {
  postURL: string
}

export default BlogShare
