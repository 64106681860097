import React from "react"
import { Link } from "gatsby"
import { Post } from "./blog-types"

// UI
import Headline from "~ui/Headline"
import CategoryTag from "~components/blog/category-tag"
import { GatsbyImage } from "gatsby-plugin-image"

const PreviewCard = (props) => {
  return (
    <article
      className="bg-white dark:bg-gray-800 shadow-2xl rounded-3xl overflow-hidden fs-unmask"
      key={props.linkTo}
      data-sal="slide-up"
      data-sal-delay={props.delay}
    >
      <Link to={props.linkTo}>
        <GatsbyImage
          alt={props.image.description}
          image={props.image.gatsbyImageData}
          imgStyle={{ borderRadius: "16px 16px 0 0" }}
        />
        <div className="p-6">
          <Headline level={4} textSize="text-xl leading-tight">
            {props.title}
          </Headline>
          {props.category && (
            <div>
              <CategoryTag category={props.category} color="light" />
            </div>
          )}
        </div>
      </Link>
    </article>
  )
}

const ArticlePreview: React.FC<{ posts: Post[] }> = ({ posts }) => {
  return (
    <div className="container mx-auto">
      <div className="px-6 lg:px-12 mb-16 md:mb-32" data-sal="slide-up">
        <Headline level={3} textSize="text-3xl lg:text-4xl 2xl:text-5xl" className="mb-6 md:mb-8">
          Continue Reading
        </Headline>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
          {posts.map((post, i) => (
            <PreviewCard
              key={post.urlSlug}
              linkTo={`/blog/${post.urlSlug}`}
              image={post.postImage}
              title={post.title}
              category={post.category}
              delay={i * 100}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ArticlePreview
