import React from "react"
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

// UI
import { Post } from "~components/blog/blog-types"
import Seo from "~components/seo"
import Layout from "~components/layout"
import Hero from "~components/blog/hero"
import ContentfulRichText from "~components/blog/contentful-rich-text"
import ArticlePreview from "~components/blog/article-preview"
import BlogPreFooter from "~components/blog/pre-footer"
import BlogShare from "~components/blog/share"

type propType = {
  data: { contentfulBlogPost: Post; previews: { nodes: Post[] } }
}
function BlogPostTemplate(props: propType): JSX.Element {
  const post: Post = props.data?.contentfulBlogPost
  const previewPosts: Post[] = props.data?.previews.nodes
  const metaSrc = getSrc(post.postImage.gatsbyImageData)

  return (
    <Layout blog>
      <Seo
        title={`${post.title} | Canopy Servicing`}
        description={post.seoDescription}
        keywords={post.seoKeywords ?? []}
        image={metaSrc}
        canonicalTag={post.canonicalTag}
      />
      <Hero
        postImage={post.postImage}
        title={post.title}
        author={post.author}
        rawDate={post.rawDate}
        createdOn={post.createdOn}
        extendedTitle={post.extendedTitle}
        category={post.category}
      />
      <div className="sm:px-6 mb-16 lg:mb-32">
        <div className="px-6 md:px-12 xl:px-48 2xl:px-56 max-w-7xl mx-auto lg:text-lg lg:leading-tight">
          <ContentfulRichText richText={post.body} />
          <BlogShare postURL={post.urlSlug} />
        </div>
      </div>
      <ArticlePreview posts={previewPosts} />
      <BlogPreFooter />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($urlSlug: String) {
    contentfulBlogPost(urlSlug: { eq: $urlSlug }) {
      ...blogPostFields
      postImage {
        description
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, height: 800, width: 1200, quality: 85)
      }
    }
    previews: allContentfulBlogPost(sort: { fields: [createdOn], order: DESC }, limit: 3) {
      nodes {
        ...blogPostFields
        postImage {
          description
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, height: 360, width: 700, quality: 60)
        }
      }
    }
  }
`
