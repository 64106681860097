// @ts-nocheck
/* eslint-disable */
// this is a copypasta from the contentful-rich-text repo
// it works and is fine on some level but our tooling hates it

import React from "react"
import PropTypes from "prop-types"

import { INLINES, BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
    [MARKS.ITALIC]: (text) => <i className="font-italic">{text}</i>,
    [MARKS.UNDERLINE]: (text) => <u className="underline">{text}</u>,
    [MARKS.CODE]: (text) => <code className="font-mono px-1 py-2 mx-1 bg-gray-50 rounded text-sm">{text}</code>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.indexOf("youtube.com/embed") !== -1) {
        return (
          <div className="video-container">
            <iframe
              id="ytplayer"
              className="video"
              src={node.data.uri}
              type="text/html"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        )
      } else
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer" className="underline hover:text-blue-500">
            {children}
          </a>
        )
    },
    [BLOCKS.HEADING_1]: (node, children) => (
      <h2 className="text-2xl md:text-4xl text-left text-black dark:text-white leading-tight mb-4 md:mb-8 pt-6">
        {children}
      </h2>
    ),
    [BLOCKS.HEADING_2]: (node, children) => {
      return (
        <h2 className="text-xl md:text-3xl text-left text-black dark:text-white leading-tight mb-4 md:mb-8 pt-6">
          {children}
        </h2>
      )
    },
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3 className="text-lg md:text-2xl text-left text-black dark:text-white leading-tight mb-4 md:mb-8 pt-6">
        {children}
      </h3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <h4 className="text-base md:text-xl text-left text-black dark:text-white leading-tight mb-4 md:mb-8 pt-6">
        {children}
      </h4>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <h5 className="text-base md:text-lg text-left text-black dark:text-white leading-tight mb-4 md:mb-8 pt-6">
        {children}
      </h5>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <h6 className="text-sm md:text-base text-left text-black dark:text-white leading-tight mb-4 md:mb-8 pt-6">
        {children}
      </h6>
    ),

    [BLOCKS.OL_LIST]: (node, children) => <ol className="list-decimal pl-4">{children}</ol>,
    [BLOCKS.UL_LIST]: (node, children) => <ul className="list-disc pl-4">{children}</ul>,

    [BLOCKS.LIST_ITEM]: (node, children) => <li className="mb-1">{children}</li>,
    [BLOCKS.PARAGRAPH]: (node, children) => {
      if (node.content[0].value === "") {
        return <br />
      } else {
        return <p className="lg:leading-loose mb-6">{children}</p>
      }
    },
    [BLOCKS.QUOTE]: (children) => (
      <blockquote className="border-l-2 border-blue-500 pl-6 leading-relaxed my-6">
        <>"{children.content[0].content[0].value}"</>
      </blockquote>
    ),
    [BLOCKS.HR]: () => <hr className="mb-6" />,
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { gatsbyImageData, description } = node.data.target

      return <GatsbyImage image={getImage(gatsbyImageData)} alt={description} className="mb-10" />
    },
  },
}

const ContentfulRichText: React.FC<{ richText: unknown }> = ({ richText }) => {
  return <div>{renderRichText(richText, options)}</div>
}

ContentfulRichText.propTypes = {
  richText: PropTypes.any.isRequired,
}

export default ContentfulRichText
