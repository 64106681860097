import React from "react"
import { Post } from "./blog-types"

// UI
import Headline from "~ui/Headline"
import CategoryTag from "~components/blog/category-tag"
import { GatsbyImage } from "gatsby-plugin-image"
import Spirograph from "~components/artwork/Spirograph"
import Aura from "~components/artwork/Aura"

const Hero = ({
  postImage,
  title,
  author,
  rawDate,
  createdOn,
  extendedTitle,
  category,
}: Partial<Post> & { category: string[] }): JSX.Element => (
  <div className="px-6 py-10 md:py-16">
    <div className="sm:px-6 md:px-12 xl:px-48 2xl:px-56 mb-10 md:mb-16 max-w-7xl mx-auto text-sm md:text-base">
      <Headline level={1} textSize="text-3xl md:text-5xl 2xl:text-6xl" data-sal="slide-up">
        {extendedTitle && (
          <span className="block text-lg md:text-2xl 2xl:text-3xl text-blue-500 mb-1 md:mb-2">{extendedTitle}</span>
        )}
        {title}
      </Headline>
      <div className="text-sm" data-sal="slide-up" data-sal-delay="75">
        <CategoryTag category={category} color="light" />
        <br className="sm:hidden" />
        <div className="inline-block mt-0">
          <time dateTime={rawDate}>{createdOn}</time> &nbsp;&middot;&nbsp; By {author}
        </div>
      </div>
    </div>
    <Spirograph
      shape={1}
      width="w-full sm:w-3/4 xl:w-3/5"
      position="right-0"
      offsetY="-translate-y-3/4 sm:-translate-y-1/5 lg:-translate-y-1/4 xl:-translate-y-1/10"
      offsetX="translate-x-3/5 lg:translate-x-1/2"
    />
    <Aura
      size="w-full2x sm:w-full1/2x md:w-full"
      position="right-0"
      offsetY="-translate-y-1/2 sm:-translate-y-1/3"
      offsetX="translate-x-1/2"
      opacity="opacity-50"
    />
    <Aura
      color="purple"
      size="w-full1/2x md:w-full"
      position="right-0"
      offsetY="-translate-y-3/20 md:-translate-y-1/20"
      offsetX="translate-x-1/2 md:translate-x-3/5"
      opacity="opacity-30"
    />
    {postImage && (
      <div
        className={`bg-gray-800 shadow-2xl rounded-3xl overflow-hidden sm:mx-6 md:mx-auto max-w-5xl`}
        data-sal="slide-up"
        data-sal-delay="150"
      >
        <GatsbyImage
          alt={postImage.description}
          image={postImage.gatsbyImageData}
          imgStyle={{ borderRadius: 24, width: "100%" }}
        />
      </div>
    )}
  </div>
)

export default Hero
